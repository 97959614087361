import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import StickySidebar from 'sticky-sidebar';

global.ResizeSensor = ResizeSensor;

global.initHelp = function () {
    new StickySidebar('.table-contents-outer', {
        container: '.help-content > .row',
        topSpacing: 87,
        bottomSpacing: 10,
        minWidth: 991
    });

    $('.table-contents a').click(function (event) {
        event.preventDefault();
        $(window).scrollTo($(this).attr('href'), 200, {interrupt: true, offset: { top: -40 }});
    });
};
