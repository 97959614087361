import 'slick-carousel';

global.initHome = function () {
    $('.home-partners').slick({
        infinite: true,
        variableWidth: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        swipeToSlide: true,
        pauseOnFocus: false,
    });
};
