import 'slick-carousel';

global.initProduct = function () {
    $('.product-page__slider')
        .slick()
        .on('beforeChange', function (event, slick, current, next) {
            $('.product-page__thumbnail').removeClass('active');
            $(`.product-page__thumbnail[data-slide="${next}"]`).addClass('active');
        });

    $('.product-page__thumbnail').click(function () {
        $('.product-page__slider').slick('slickGoTo', $(this).data('slide'));
    });
};
