// We use some ES6 specific functions
import '@babel/polyfill';

import './jquery';

import 'bootstrap-sass';
import 'jquery.scrollto';
import 'lazysizes';

import './help';
import './magazine';
import './home';

import './shop';
